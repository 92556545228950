/* eslint-disable no-underscore-dangle */
import { applyMiddleware, createStore, compose } from 'redux';
import logger from 'redux-logger';
import thunk from 'redux-thunk';

import rootReducer from '../reducers/index';

let store;

if (window._env_.NODE_ENV === 'development') {
  const composeEnhanced =
    window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
  store = createStore(
    rootReducer,
    composeEnhanced(applyMiddleware(thunk), applyMiddleware(logger))
  );
} else {
  store = createStore(
    rootReducer,
    compose(applyMiddleware(thunk), applyMiddleware(logger))
  );
}

export default { ...store };
