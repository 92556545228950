import enMessages from './en';
import frMessages from './fr';
import nlMessages from './nl';

const languages = {
  fr: {
    messages: frMessages,
    label: 'Français',
  },
  en: {
    messages: enMessages,
    label: 'English',
  },
  nl: {
    messages: nlMessages,
    label: 'Nederlands',
  },
};

export default languages;
