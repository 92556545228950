import EquansTheme from './equans';

const themes = {
  EQUANS: EquansTheme,
};

export function getTheme() {
  return themes.EQUANS;
}

export default themes;
