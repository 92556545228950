/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import dayjs from 'dayjs';
import Iframe from '../Iframe';

import { PICKER_KIBANA_APPLICATIONS } from '../../../services/constants';

export function generateUrl(
  application,
  from,
  to,
  refreshStatus,
  refreshInterval
) {
  const { url, useTimePicker = false } = application;
  // TODO: when application type is available, check application type instead of URL contents
  if (!PICKER_KIBANA_APPLICATIONS.every((v) => !url.includes(v))) {
    const regexTimeKibana = /time:\([^)]*/;
    const formatTimeKibana = 'YYYY-MM-DDTHH:mm:ss';
    const generatedTimeFilterKibana = `time:(from:'${from.format(
      formatTimeKibana
    )}',to:'${to.now ? 'now' : to.format(formatTimeKibana)}'`;
    const regexRefreshKibana = /refreshInterval:\([^)]*/;
    const refreshStatusKibana = refreshStatus ? 'f' : 't';
    const generatedRefreshKibana = `refreshInterval:(pause:!${refreshStatusKibana},value:${refreshInterval}`;
    return url
      .replace(regexTimeKibana, generatedTimeFilterKibana)
      .replace(regexRefreshKibana, generatedRefreshKibana);
  }
  if (useTimePicker) {
    const urlConfig = new URL(url);
    urlConfig.searchParams.set('from', from.unix());
    urlConfig.searchParams.set('to', to.now ? dayjs().unix() : to.unix());
    if (refreshStatus) url.searchParams.set('refreshInterval', refreshInterval);
    return urlConfig.href;
  }
  return url;
}

function ApplicationsMenu(props) {
  const { selectedApplication, from, to, refreshStatus, refreshInterval } =
    props;
  const { applications } = selectedApplication;

  const [selectedTab, setTab] = useState();
  const handleChange = (_, newValue) => setTab(newValue);

  console.log(selectedApplication);

  useEffect(() => {
    setTab(undefined);
  }, [selectedApplication]);

  if (!applications || Object.keys(applications).length === 0) {
    return <p>Application is not well configured.</p>;
  }

  if (!selectedTab) {
    setTab(
      generateUrl(
        Object.values(applications)[0],
        from,
        to,
        refreshStatus,
        refreshInterval
      )
    );
  }

  return (
    <div
      style={{
        height: '93%',
      }}
    >
      <Tabs
        value={selectedTab}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        variant="fullWidth"
      >
        {Object.keys(applications).map((key) => {
          const tab = applications[key];
          const generatedUrl = generateUrl(
            tab,
            from,
            to,
            refreshStatus,
            refreshInterval
          );
          return <Tab key={tab.name} label={tab.name} value={generatedUrl} />;
        })}
      </Tabs>
      <Iframe name="Application is displayed in tab." url={selectedTab} />
    </div>
  );
}

export default ApplicationsMenu;
