/* eslint-disable default-param-last */
import dayjs from 'dayjs';
import { CHANGE_AUTOREFRESH, CHANGE_TIME } from '../actions/time.actions';
import { QUICK } from '../../services/constants';

const initialState = {
  title: {
    type: QUICK,
    id: 'picker_title_today',
  },
  from: dayjs().startOf('day'),
  to: dayjs().endOf('day'),
  refreshStatus: false,
  refreshInterval: 30000,
};

const timeReducer = (state = initialState, action) => {
  let nextState;
  switch (action.type) {
    case CHANGE_TIME:
      nextState = {
        ...state,
        title: action.payload.title,
        from: action.payload.from,
        to: action.payload.to,
      };
      return nextState || state;
    case CHANGE_AUTOREFRESH:
      nextState = {
        ...state,
        refreshStatus: action.payload.refreshStatus,
        refreshInterval: action.payload.refreshInterval,
      };
      return nextState || state;
    default:
      return state;
  }
};

export default timeReducer;
