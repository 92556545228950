import dayjs from 'dayjs';
import { QUICK, RELATIVE } from '../../services/constants';

export const CHANGE_TIME = '@time/CHANGE_TIME';
export const CHANGE_AUTOREFRESH = '@time/CHANGE_AUTOREFRESH';

const changeQuickTime = (timerange) => {
  let buffer;
  let from;
  let to;
  switch (timerange) {
    case 'today':
      from = dayjs().startOf('day');
      to = dayjs().endOf('day');
      break;
    case 'thisweek':
      from = dayjs().startOf('week');
      to = dayjs().endOf('week');
      break;
    case 'thismonth':
      from = dayjs().startOf('month');
      to = dayjs().endOf('month');
      break;
    case 'thisyear':
      from = dayjs().startOf('year');
      to = dayjs().endOf('year');
      break;
    case 'thedaysofar':
      from = dayjs().startOf('day');
      break;
    case 'weektodate':
      from = dayjs().startOf('week');
      break;
    case 'monthtodate':
      from = dayjs().startOf('month');
      break;
    case 'yeartodate':
      from = dayjs().startOf('year');
      break;
    case 'yesterday':
      buffer = dayjs().subtract(1, 'day');
      from = buffer.startOf('day');
      to = buffer.endOf('day');
      break;
    case 'daybeforeyesterday':
      buffer = dayjs().subtract(2, 'day');
      from = buffer.startOf('day');
      to = buffer.endOf('day');
      break;
    case 'thisdaylastweek':
      buffer = dayjs().subtract(1, 'week');
      from = buffer.startOf('day');
      to = buffer.endOf('day');
      break;
    case 'previousweek':
      buffer = dayjs().subtract(1, 'week');
      from = buffer.startOf('week');
      to = buffer.endOf('week');
      break;
    case 'previousmonth':
      buffer = dayjs().subtract(1, 'month');
      from = buffer.startOf('month');
      to = buffer.endOf('month');
      break;
    case 'previousyear':
      buffer = dayjs().subtract(1, 'year');
      from = buffer.startOf('year');
      to = buffer.endOf('year');
      break;
    case 'last15minutes':
      from = dayjs().subtract(15, 'minute');
      break;
    case 'last30minutes':
      from = dayjs().subtract(30, 'minute');
      break;
    case 'last1hour':
      from = dayjs().subtract(1, 'hour');
      break;
    case 'last4hours':
      from = dayjs().subtract(4, 'hour');
      break;
    case 'last12hours':
      from = dayjs().subtract(12, 'hour');
      break;
    case 'last24hours':
      from = dayjs().subtract(24, 'hour');
      break;
    case 'last7days':
      from = dayjs().subtract(7, 'day');
      break;
    case 'last30days':
      from = dayjs().subtract(30, 'day');
      break;
    case 'last60days':
      from = dayjs().subtract(60, 'day');
      break;
    case 'last90days':
      from = dayjs().subtract(90, 'day');
      break;
    case 'last6months':
      from = dayjs().subtract(6, 'month');
      break;
    case 'last1year':
      from = dayjs().subtract(1, 'year');
      break;
    case 'last2years':
      from = dayjs().subtract(2, 'year');
      break;
    case 'last5years':
    default:
      // fallback: last 5 years
      from = dayjs().subtract(5, 'year');
      break;
  }

  return { from, to };
};

export const changeTime =
  (title, from = dayjs(0), to = { now: true }) =>
  async (dispatch) => {
    if (title?.type === QUICK) {
      const newQuickTime = changeQuickTime(title?.timerange);
      dispatch({
        type: CHANGE_TIME,
        payload: {
          title,
          from: newQuickTime?.from ?? from,
          to: newQuickTime?.to ?? to,
        },
      });
    } else if (title?.type === RELATIVE) {
      dispatch({
        type: CHANGE_TIME,
        payload: {
          title,
          from: dayjs().subtract(title.amount, title.unit),
          to,
        },
      });
    } else {
      dispatch({
        type: CHANGE_TIME,
        payload: {
          title,
          from,
          to,
        },
      });
    }
  };

export const changeAutorefresh =
  (refreshStatus, refreshInterval) => async (dispatch) => {
    dispatch({
      type: CHANGE_AUTOREFRESH,
      payload: {
        refreshStatus,
        refreshInterval,
      },
    });
  };

export const refreshTimeInterval = () => async (dispatch, getState) => {
  const { time } = getState();
  console.log('refresh');
  dispatch(changeTime(time?.title));
};
