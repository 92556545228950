/* eslint-disable no-underscore-dangle */
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createHTTPHelper } from 'fas-http-helper';
import { Helmet } from 'react-helmet';

import App from './App';
import store from './redux/store';

import './index.css';

createHTTPHelper(
  window._env_.NODE_ENV,
  window._env_.REACT_APP_API_ADDRESS,
  window._env_.REACT_APP_API_PORT,
  window._env_.REACT_APP_API_PATH,
  window._env_.REACT_APP_API_IS_SECURE
);

const APP_NAME = window._env_.REACT_APP_TITLE;

ReactDOM.render(
  <>
    <Helmet>
      <title>{APP_NAME}</title>
    </Helmet>
    <Provider store={store}>
      <App />
    </Provider>
  </>,
  document.getElementById('root')
);
