/* eslint-disable default-param-last */
import { WELCOME_TYPE } from '../../services/constants';
import {
  SET_CURRENT_APP,
  SET_TOKEN,
  GET_CATEGORIES,
  GET_CATEGORIES_SUCCESS,
  GET_CATEGORIES_ERROR,
} from '../actions/category.actions';

const initialState = {
  currentApp: {
    type: WELCOME_TYPE,
  },
  user: null,
  token: '',
  loading: false,
  categories: [],
};

function categoryReducer(state = initialState, action) {
  let nextState;

  if (action.type === SET_CURRENT_APP) {
    if (!action.payload) {
      return state;
    }
    nextState = {
      ...state,
      currentApp: action.payload.application,
    };
    return nextState || state;
  }

  if (action.type === SET_TOKEN) {
    if (!action.payload) {
      return state;
    }
    nextState = {
      ...state,
      token: action.payload.token,
    };
    return nextState || state;
  }

  if (action.type === GET_CATEGORIES) {
    nextState = {
      ...state,
    };
    return nextState || state;
  }

  if (action.type === GET_CATEGORIES_SUCCESS) {
    if (!action.payload) {
      return state;
    }
    nextState = {
      ...state,
      categories: action.payload,
    };
    return nextState || state;
  }

  if (action.type === GET_CATEGORIES_ERROR) {
    if (!action.payload) {
      return state;
    }
    nextState = {
      ...state,
    };
    return nextState || state;
  }
  return state;
}

export default categoryReducer;
