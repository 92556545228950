import { withStyles } from '@material-ui/core/styles';
import { forwardRef } from 'react';
import Iframe from './Iframe';

const styles = () => ({
  appFrame: {
    flexGrow: 2,
    border: 0,
    display: 'block',
    width: '100%',
    height: '100%',
  },
});

export default withStyles(styles)(forwardRef(Iframe));
