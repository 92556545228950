/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid, TextField } from '@material-ui/core';
import { injectIntl } from 'react-intl';

const units = ['second', 'minute', 'hour', 'day', 'week', 'month', 'year'];

class RelativePicker extends Component {
  constructor(props) {
    super(props);
    const { interval } = props;
    this.state = {
      intervalAmount: interval.amount,
      intervalUnit: interval.unit,
    };
  }

  onChangeIntervalAmount = (event) => {
    const { onChange } = this.props;
    const { intervalUnit } = this.state;
    let intervalAmount = event.target.value;
    intervalAmount = intervalAmount < 1 ? 1 : intervalAmount;
    this.setState({ intervalAmount }, () =>
      onChange({ amount: intervalAmount, unit: intervalUnit })
    );
  };

  onChangeIntervalUnit = (event) => {
    const { onChange } = this.props;
    const { intervalAmount } = this.state;
    const intervalUnit = event.target.value;
    this.setState({ intervalUnit }, () =>
      onChange({ amount: intervalAmount, unit: intervalUnit })
    );
  };

  render() {
    const { label, intl } = this.props;
    const { intervalAmount, intervalUnit } = this.state;
    return (
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <TextField
            type="number"
            label={label}
            value={intervalAmount}
            onChange={this.onChangeIntervalAmount}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            select
            value={intervalUnit}
            onChange={this.onChangeIntervalUnit}
            SelectProps={{
              native: true,
            }}
          >
            {units.map((option) => (
              <option key={option} value={option}>
                {`
                  \u00a0
                  ${intl.formatMessage({
                    id: `picker_unit_${option}${
                      intervalAmount > 1 ? '_plural' : ''
                    }`,
                  })}
                  ${intervalAmount > 1 ? '' : '\u00a0'}
                `}
              </option>
            ))}
          </TextField>
        </Grid>
      </Grid>
    );
  }
}

RelativePicker.propTypes = {
  onChange: PropTypes.func.isRequired,
  interval: PropTypes.object.isRequired,
  label: PropTypes.string,
  intl: PropTypes.object.isRequired,
};

export default injectIntl(RelativePicker);
