/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/forbid-prop-types */
/* eslint-disable class-methods-use-this */
/* eslint-disable react/prop-types */
import React, { Component } from 'react';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import Collapse from '@material-ui/core/Collapse';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import Icon from '@material-ui/core/Icon';

import { FasIcon, isFasIcon } from 'fas-react-style';

import PropTypes from 'prop-types';

class ItemMenu extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: true,
    };
  }

  toggleItem = () => {
    this.setState((prevState) => ({ ...prevState, isOpen: !prevState.isOpen }));
  };

  handleClickOnApp = (event, application, closeMenu) => {
    event.preventDefault();
    const { setCurrentApp } = this.props;
    setCurrentApp(application);
    closeMenu();
  };

  getImage = (application) => (
    <ListItemIcon>
      <img
        src={application.image}
        alt={application.name}
        width="24"
        height="24"
      />
    </ListItemIcon>
  );

  getIcon = (application) => {
    if (isFasIcon(application.icon)) {
      return (
        <ListItemIcon>
          <FasIcon>{application.icon}</FasIcon>
        </ListItemIcon>
      );
    }
    console.log(application.icon);
    return (
      <ListItemIcon>
        <Icon>{application.icon}</Icon>
      </ListItemIcon>
    );
  };

  getApplications = (category, closeMenu) => {
    const { applications, classes } = this.props;
    return (
      <List component="div" disablePadding>
        {applications
          .sort((a, b) => a.position - b.position)
          .map((application) => (
            <Link
              onClick={(event) =>
                this.handleClickOnApp(event, application, closeMenu)
              }
              href={application.url}
              className={classes.link}
            >
              <ListItem className={classes.nested} key={application.id}>
                {application.image
                  ? this.getImage(application)
                  : this.getIcon(application)}
                {application.name}
              </ListItem>
            </Link>
          ))}
      </List>
    );
  };

  render() {
    const { classes, category, closeMenu } = this.props;
    const { isOpen } = this.state;
    return (
      <div className={classes.root}>
        <ListItem className={classes.category} button onClick={this.toggleItem}>
          {category.icon && (
            <ListItemIcon>
              <Icon>{category.icon}</Icon>
            </ListItemIcon>
          )}
          <ListItemText primary={category.name} />
          {isOpen ? <ExpandLess /> : <ExpandMore />}
        </ListItem>
        <Collapse in={isOpen} timeout="auto" unmountOnExit>
          {this.getApplications(category, closeMenu)}
        </Collapse>
      </div>
    );
  }
}

ItemMenu.propTypes = {
  applications: PropTypes.array.isRequired,
};

export default ItemMenu;
