/* eslint-disable default-param-last */
import { AUTHENTICATE_SUCCESS } from 'fas-react-configuration-helper/actions/keycloakActions';

import {
  CHANGE_THEME,
  CHANGE_THEME_SUCCESS,
  CHANGE_THEME_ERROR,
  CHANGE_LANGUAGE,
  CHANGE_LANGUAGE_SUCCESS,
  CHANGE_LANGUAGE_ERROR,
} from '../actions/configuration.actions';

const initialState = {
  configuration: {},
  loading: false,
  theme: 'EQUANS',
};

const configurationReducer = (state = initialState, action) => {
  let nextState;
  switch (action.type) {
    case AUTHENTICATE_SUCCESS:
      nextState = {
        ...state,
        theme: action.payload.theme,
        language: action.payload.language,
      };
      return nextState || state;
    case CHANGE_THEME:
      nextState = {
        ...state,
        loading: true,
      };
      return nextState || state;
    case CHANGE_THEME_SUCCESS:
      nextState = {
        ...state,
        loading: false,
        theme: action.payload,
      };
      return nextState || state;
    case CHANGE_THEME_ERROR:
      nextState = {
        ...state,
        loading: false,
      };
      return nextState || state;
    case CHANGE_LANGUAGE:
      nextState = {
        ...state,
        loading: true,
      };
      return nextState || state;
    case CHANGE_LANGUAGE_SUCCESS:
      nextState = {
        ...state,
        loading: false,
        language: action.payload,
      };
      return nextState || state;
    case CHANGE_LANGUAGE_ERROR:
      nextState = {
        ...state,
        loading: false,
      };
      return nextState || state;
    default:
      return state;
  }
};

export default configurationReducer;
