/* eslint-disable react/forbid-prop-types */
import { Box, Button, Grid, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import { FormattedMessage, injectIntl } from 'react-intl';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RelativePicker } from '.';
import { changeTime } from '../../redux/actions/time.actions';
import { RELATIVE } from '../../services/constants';

class RelativePanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      interval: {
        amount: 1,
        unit: 'day',
      },
    };
  }

  onChangeInterval = (interval) => {
    this.setState(() => ({ interval }));
  };

  onSubmit = () => {
    const { airngbarCallback, changeTimeProp } = this.props;
    const { interval } = this.state;
    changeTimeProp({
      type: RELATIVE,
      amount: interval.amount,
      unit: interval.unit,
    });
    airngbarCallback();
  };

  render() {
    const { interval } = this.state;
    const { intl } = this.props;
    return (
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <RelativePicker
            interval={interval}
            onChange={this.onChangeInterval}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            value={intl.formatMessage({ id: 'picker_ago' })}
            disabled
          />
          <Grid container spacing={0}>
            <Grid item xs={3} />
            <Grid item xs={9}>
              <Box marginTop={3}>
                <Button variant="contained" onClick={this.onSubmit}>
                  <FormattedMessage id="picker_ok" />
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

RelativePanel.propTypes = {
  airngbarCallback: PropTypes.func.isRequired,
  changeTimeProp: PropTypes.func.isRequired,
  intl: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  changeTimeProp: (title, from, to) => dispatch(changeTime(title, from, to)),
});

export default connect(null, mapDispatchToProps)(injectIntl(RelativePanel));
