import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import SyncIcon from '@material-ui/icons/Sync';
import SyncDisabledIcon from '@material-ui/icons/SyncDisabled';
import Button from '@material-ui/core/Button';
import Tooltip from '@material-ui/core/Tooltip';
import { connect } from 'react-redux';
import { useIntl } from 'react-intl';
import makeStyles from '@material-ui/styles/makeStyles';
import { changeAutorefresh as changeAutorefreshAction } from '../../redux/actions/time.actions';

const useStyles = makeStyles((theme) => ({
  userButton: (props) => ({
    marginRight: theme.spacing(2),
    textTransform: 'none',
    color: props.refreshStatus ? 'inherit' : 'lightgrey',
  }),
}));

function AutoRefreshButton({ refresh, refreshStatus, changeAutorefresh }) {
  const classes = useStyles({ refreshStatus });
  const intl = useIntl();

  const toggleRefresh = () =>
    changeAutorefresh(!refreshStatus, refresh?.interval);

  useEffect(() => {
    changeAutorefresh(refresh?.autoplay, refresh?.interval);
  }, [refresh, changeAutorefresh]);

  if (refresh?.enabled) {
    const tooltipTitleTranslated = intl.formatMessage({
      id: refreshStatus ? 'refresh_enabled' : 'refresh_disabled',
    });
    return (
      <Tooltip title={tooltipTitleTranslated}>
        <Button
          color="inherit"
          onClick={toggleRefresh}
          className={classes.userButton}
        >
          {refreshStatus ? <SyncIcon /> : <SyncDisabledIcon />}
          &nbsp;Auto refresh
        </Button>
      </Tooltip>
    );
  }

  return null;
}

AutoRefreshButton.propTypes = {
  refresh: PropTypes.shape({
    enabled: PropTypes.bool,
    autoplay: PropTypes.bool,
    interval: PropTypes.number,
  }),
  refreshStatus: PropTypes.bool.isRequired,
  changeAutorefresh: PropTypes.func.isRequired,
};

AutoRefreshButton.defaultProps = {
  refresh: {
    enabled: false,
    autoplay: false,
    interval: 0,
  },
};

const mapStateToProps = (state) => ({
  refreshStatus: state.time.refreshStatus,
});

const mapDispatchToProps = (dispatch) => ({
  changeAutorefresh: (refreshStatus, refreshInterval) =>
    dispatch(changeAutorefreshAction(refreshStatus, refreshInterval)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(AutoRefreshButton));
