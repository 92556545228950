const messages = {
  languages: 'Talen',
  logout: 'Uitloggen',
  now: 'nu',

  picker_absolute: 'absoluut',
  picker_autorefresh: 'auto herladen',
  picker_ago: 'geleden',
  picker_from: 'Van:',
  picker_ok: 'go',
  picker_on: 'on',
  picker_off: 'off',
  picker_quick: 'snel',
  picker_relative: 'relatief',
  picker_title_from: 'van',
  picker_title_last: 'laatste',
  picker_title_to: 'tot',
  picker_title_today: 'vandaag',
  picker_to: 'Tot:',

  picker_quick_today: 'Vandaag',
  picker_quick_thisweek: 'Deze week',
  picker_quick_thismonth: 'Deze maand',
  picker_quick_thisyear: 'Deze jaar',
  picker_quick_thedaysofar: 'Dag dusver',
  picker_quick_weektodate: 'Week dusver',
  picker_quick_monthtodate: 'Maand dusver',
  picker_quick_yeartodate: 'Jaar dusver',
  picker_quick_yesterday: 'Gisteren',
  picker_quick_daybeforeyesterday: 'Eergisteren',
  picker_quick_thisdaylastweek: 'Deze dag vorige week',
  picker_quick_previousweek: 'Afgelopen week',
  picker_quick_previousmonth: 'Afgelopen maand',
  picker_quick_previousyear: 'Afgelopen jaar',
  picker_quick_last15minutes: 'Laatste 15 minuten',
  picker_quick_last30minutes: 'Laatste 30 minuten',
  picker_quick_last1hour: 'Laatste 1 uur',
  picker_quick_last4hours: 'Laatste 4 uur',
  picker_quick_last12hours: 'Laatste 12 uur',
  picker_quick_last24hours: 'Laatste 24 uur',
  picker_quick_last7days: 'Laatste 7 dagen',
  picker_quick_last30days: 'Laatste 30 dagen',
  picker_quick_last60days: 'Laatste 60 dagen',
  picker_quick_last90days: 'Laatste 90 dagen',
  picker_quick_last6months: 'Laatste 6 maanden',
  picker_quick_last1year: 'Laatste 1 jaar',
  picker_quick_last2years: 'Laatste 2 jaren',
  picker_quick_last5years: 'Laatste 5 jaren',

  picker_unit_second: 'seconde',
  picker_unit_second_plural: 'seconden',
  picker_unit_minute: 'minuut',
  picker_unit_minute_plural: 'minuten',
  picker_unit_hour: 'uur',
  picker_unit_hour_plural: 'uur',
  picker_unit_day: 'dag',
  picker_unit_day_plural: 'dagen',
  picker_unit_week: 'week',
  picker_unit_week_plural: 'weken',
  picker_unit_month: 'maand',
  picker_unit_month_plural: 'maanden',
  picker_unit_year: 'jaar',
  picker_unit_year_plural: 'jaren',

  themes: "Thema's",
  refresh_enabled: 'Automatisch vernieuwen ingeschakeld',
  refresh_disabled: 'Automatisch vernieuwen uitgeschakeld',
};
// TODO: proofread

export default messages;
