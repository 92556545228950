/* eslint-disable react/require-default-props */
/* eslint-disable react/forbid-prop-types */
import React from 'react';
import PropTypes from 'prop-types';
import DayjsUtils from '@date-io/dayjs';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';

function AbsolutePicker({ date, onChange, minDate, maxDate, label }) {
  return (
    <MuiPickersUtilsProvider utils={DayjsUtils}>
      <KeyboardDateTimePicker
        value={date}
        onChange={onChange}
        label={label}
        format="YYYY-MM-DD HH:mm"
        ampm={false}
        showTodayButton
        minDate={minDate}
        maxDate={maxDate}
      />
    </MuiPickersUtilsProvider>
  );
}

AbsolutePicker.propTypes = {
  date: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  minDate: PropTypes.object,
  maxDate: PropTypes.object,
  label: PropTypes.string,
};

export default AbsolutePicker;
