/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import LinearProgress from '@material-ui/core/LinearProgress';

import ItemMenu from './ItemMenu';

class Menu extends PureComponent {
  displayMenu = () => {
    const { loading, categories, toggleDrawer, classes } = this.props;
    if (!categories) {
      return <List />;
    }
    return (
      <>
        {loading && <LinearProgress />}
        <List>
          {categories
            .sort((a, b) => a.position - b.position)
            .map((item) => (
              <ItemMenu
                className={classes.itemMenu}
                key={item.id}
                category={item}
                applications={item.applications}
                closeMenu={toggleDrawer}
              />
            ))}
        </List>
      </>
    );
  };

  render() {
    const { classes, isOpen, toggleDrawer } = this.props;
    return (
      <div onClick={toggleDrawer}>
        <Drawer open={isOpen}>
          <div
            className={classes.list}
            role="presentation"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
          >
            {this.displayMenu()}
          </div>
        </Drawer>
      </div>
    );
  }
}

export default Menu;
